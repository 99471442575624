import { debounce } from 'lodash-es';

let blockEl;

function setLineLengths() {
  const polylineEls = blockEl.querySelectorAll('polyline');

  polylineEls.forEach((el) => {
    const length = el.getTotalLength();
    el.setAttribute('style', `--path-length: ${length};`);
  });
}

function init() {
  blockEl = document.querySelector('.wp-block-services-graphic');

  if (!blockEl) {
    return;
  }

  const listEls = blockEl.querySelectorAll('li');

  listEls.forEach((el) => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          el.classList.add('in-view');
        }
      });
    }, { threshold: 1 });
    observer.observe(el);
  });

  setLineLengths();
  window.addEventListener('resize', debounce(setLineLengths));
}


export default {
  init,
};
